<template>
  <div v-bind:key="refreshKey">
    <slot name="table-top-actions" v-bind:selectedIds="selectedIds"/>
    <b-table responsive v-bind:items="rows" v-bind:fields="fields" no-local-sorting
             v-bind:sort-by.sync="sort.field" v-bind:sort-desc.sync="sortIsDesc"
             striped  hover @row-clicked="onRowClicked" v-bind:busy="loading"
             @sort-changed="onSortChanged"
             style="white-space: nowrap; padding-bottom: 30px"
    >
      <template #head(selection)="data">
        <b-form-checkbox v-bind:indeterminate="isIndeterminate(selected)" v-model="selected.all"
                         style="margin-top: 7px"
                         @input="val=>toggleAllRow(val)" inline></b-form-checkbox>
      </template>
      <template #cell(selection)="data">
        <b-form-checkbox v-model="selectedIds[data.item.id]" inline @input="val=>toggleRow(data.item, val)"></b-form-checkbox>
      </template>
      <template #cell(orgId)="data">
        {{ getOrganizationNameById(data.value) }}
      </template>
    </b-table>
    <!--{{pagination}}-->
    <div class="mt-2 text-center">
      <div class="d-inline-block">
        <b-pagination
            v-model="pagination.page"
            v-bind:total-rows="pagination.total"
            v-bind:per-page="pagination.size"
            @page-click="onPageClick"
            align="center"
        ></b-pagination>
      </div>
      <div class="d-inline-block">
        <b-select class="d-inline-block" style="width: 70px" size="sm" v-model="pagination.size"  @change="refresh">
          <b-select-option v-bind:value="10">10</b-select-option>
          <b-select-option v-bind:value="25">25</b-select-option>
          <b-select-option v-bind:value="50">50</b-select-option>
          <b-select-option v-bind:value="100">100</b-select-option>
        </b-select>/{{ pagination.total }} {{$t('columns.counterNames.users')}}

      </div>

    </div>
  </div>
</template>

<script>
import mixinBase from '@/components/mixin/mixinBase';
import mixinTable from '@/components/mixin/mixinTable';
import mixinNotifications from '@/components/mixin/mixinNotifications';
import { mapActions, mapGetters } from 'vuex';
import OrganizationSelectBox from '@/components/widget/select/OrganizationSelectBox';

export default {
  name: 'BusinessesUnitsTable',
  components: {
    OrganizationSelectBox,
  },
  mixins: [mixinBase, mixinTable, mixinNotifications],

  data: () => ({
    rows: [],
    selected:{all:false},
  }),
  props: {
    filters: {
      default: () => ({})
    },
    columns:{
      default: () => ([])
    },
    hasSelection: {
      default: () => false
    },

  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.pagination.page = 1
        this.refresh()
      }
    }
  },
  created() {
    this.setPagination(1,0,10);
    this.refresh()
  },
  computed: {
    ...mapGetters('data', ['getBusinessUnitNameById', 'getOrganizationNameById']),

    fields() {
      let $this = this;
      let fields = (this.columns || []).map(column => {
        return {
          key: column.key,
          label: $this.$t(`columns.${column.key}`),
          sortable: true,
          active: column.enable,
        }
      });
      if(this.hasSelection) {
        fields.unshift({
          key: 'selection',
          label: '',
          sortable: false,
          active: true,
        });
      }
      return fields.filter(f => f.active === true);
    },
    selectedIds() {
      this.refreshKey // Little hack to recompile
      // console.log("selectedIds()",this.selected)
      return Object.keys(this.selected).filter(k=> k !== "all")
          .reduce((ans, id) => Object.assign(ans, { [id]: true }), {});
    },
  },

  methods: {
    ...mapActions('businessesUnits', ['getAllBusinessesUnits']),
    async refresh() {
      let $this = this;
      let loadingToken = this.setLoading();
      let sort = { ...$this.sort }
      if(sort.field == null){
        sort.field = "lastUpdate";
      }
      try {
        const result = await this.getAllBusinessesUnits({
              pagination: this.pagination,
              sort,
              filter: this.filters,
            }
        );
        console.log("result", result);
        this.rows = result.data;
        this.setMetaDataFromResult(result);

      } catch (err) {
        this.showErrorNotification($this.$t('globalMessages.fetchDataError', { system: $this.$t('menu.users') }), $this.getErrorDescription(err));
      } finally {
        this.setLoaded(loadingToken);
      }
    }

  }

};
</script>

<style scoped>

</style>
