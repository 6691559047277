import Pagination from '@/model/Pagination';
import Sort from '@/model/SortReq';

export default function () {
  return [
    { key: 'id' }, { key: 'name' }, { key: 'phone' }, { key: 'type',label:'Type of business unit' },
    { key: 'orgId', type: 'org', selectProps: { clearable: true, multiple: false } },
    { key: 'address' }, { key: 'contact' }, { key: 'website' }, { key: 'email' },
    // { key: 'countryCode' }, { key: 'cur' }
  ]
}
