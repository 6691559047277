<template>
  <v-select
      v-model="model"
      v-bind:multiple="multiple"

      :options="businessUnitTypes"
      label="name"
      :reduce="(org) => org.name"
      v-bind="selectProps"
      :clearable="clearable"
  >
    <template #selected-option="org">
      <div class="text-center" v-if="acronym != null && acronym !== false">
        <b-avatar size="25"  class="d-inline-block" v-b-tooltip  :title="org.name">{{initialName(org.name)}}</b-avatar>
      </div>
      <span v-else>{{ org.name }}</span>
    </template>
  </v-select>

</template>

<script>
import { initialName } from '@/model/Utils'
import BusinessUnit from '@/model/businessUnit/BusinessUnit';
export default {
  name: 'BusinessUnitTypeSelectBox',
  data: () => ({
    businessUnitTypes: [{"name": "LANDING"}, {"name": "SALES"}, {'name': "RETENTION"}],
  }),
  props: {
    value: {
      required: true,
    },
    acronym: {
      default: () => false
    },
    multiple:{
      type: Boolean,
      default: () => false
    },
    clearable:{},
    selectProps:{
      default: () => ({})
    }
  },
  computed:{
    model:{
      get(){
        return this.value;
      },
      set(value){
        this.$emit('input',value);
      }
    }
  },
  methods:{
    initialName
  }

};
</script>

<style scoped>

</style>
