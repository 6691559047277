export default function (other) {
  other = other || {}
  return {
    id: other.id || null,
    orgId: other.orgId || null,
    name: other.name || null,
    type: other.type || null,
    mailid: other.mailid || null,
    phone: other.phone || null,
    address: other.address || null,
    website: other.website || null,
    contact: other.contact || null,
    countryCode: other.countryCode || null,
    cur: other.cur || null,
    email: other.crm || null,
    externalLink: other.externalLink || null,
    token: other.token || null,
    companyClients: other.companyClients || null,
    landingClients: other.landingClients || null,
  }
}
