<template>
  <div>
    <!--Breadcrumb button-->
    <breadcrumb-button>
      <template #beforeDropdown>
        <b-button variant="success" class="btn-icon" v-b-tooltip v-bind:title="$t('operations.add')" @click="onBusinessUnitSelected(null)">
          <i class="fa-solid fa-user-plus"></i>
        </b-button>
      </template>

<!--      <template #items>-->
<!--        <b-dropdown-item @click="openColumnsModal">-->
<!--          <feather-icon-->
<!--              icon="CheckSquareIcon"-->
<!--              size="16"-->
<!--          />-->
<!--          <span class="align-middle ml-50">{{ $t('columns.self') }}</span>-->
<!--        </b-dropdown-item>-->
<!--      </template>-->

    </breadcrumb-button>
    <!--Filters-->
    <filter-card  v-model="filter" v-bind:fields="filterFields" search/>

    <!--Table Card-->
    <b-card no-body>
      <b-card-header class="pb-50">
        <!--        <h5>{{$t("menu.orgId")}}</h5>-->
      </b-card-header>
      <b-card-body>
        <businesses-units-table ref="organizationTable" v-bind:columns="columns" v-bind:filters="filter"
                             :has-selection="false" @selected="onBusinessUnitSelected" :lazy="true"/>
      </b-card-body>
    </b-card>

    <!--Modal-->
    <business-unit-modal ref="businessUnit-modal" />

  </div>

</template>

<script>
import BusinessesUnitsTable from '@/components/tables/BusinessesUnitsTable';
import FilterCard from '@/components/widget/FilterCard';
import BusinessUnitModal from '@/components/modal/BusinessUnitModal';
import ColumnsModal from '@/components/modal/columnsModal';
import BreadcrumbButton from '@/components/widget/breadcrumbButton';
import mixinBase from '@/components/mixin/mixinBase';
import mixinPermissions from '@/components/mixin/mixinPermissions';
import BusinessUnitSearchRequest from '@/model/businessUnit/BusinessUnitSearchRequest';
import BusinessUnitFilterFields from '@/model/filter/BusinessUnitFilterFields';
import { mapActions } from 'vuex';

export default {
  name: 'businessUnitsView',
  components: {BusinessesUnitsTable, FilterCard, ColumnsModal, BreadcrumbButton, BusinessUnitModal, BusinessUnitSearchRequest },
  mixins: [mixinBase,mixinPermissions],
  data() {
    return {
      //columnsNames: Object.keys(User()),
      filter: BusinessUnitSearchRequest(),
      filterLoaded: false,
      columns: [],
      filterFields: null,
    }
  },
  async created() {
    //await this.refreshData()
    this.filterFields = BusinessUnitFilterFields();
  },

  methods: {
    // ...mapActions('businessesUnits', ['refreshData']),

    onBusinessUnitSelected(selectedId) {
      console.log("on business unit Selected", selectedId)
      this.$refs['businessUnit-modal'].showModal(selectedId)
    },

  }



  };
</script>

<style scoped>

</style>
