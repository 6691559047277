<template>
  <b-modal no-fade v-model="dialog.show" size="xl" :id="`modal-businessUnit-${uniqueId}`"
           :hide-footer="true" centered @close="onDialogClose"
           no-close-on-backdrop
  >

    <template #modal-title>
     <span v-if="loading !== true && error == null && businessUnit != null">
     {{businessUnit.platformId}} -   {{ businessUnit.name }}  {{ businessUnit.acronym }}
     </span>
    </template>
    <div style="min-height: 600px">
      <!--where we write the data of the fields-->
      <div v-if="loading !== true && error == null">
        <business-unit-edit-details ref="business-unit-edit-details" v-bind:newBusinessUnit="isNewBusiness"
                                    v-model="businessUnit" @input="closeModal"/>
      </div>

      <div class="text-center position-absolute d-block w-100" style="top:50%" v-else-if="loading !== true && error != null">
        <b-alert show variant="danger" >
          <i class="fa-sharp fa-solid fa-triangle-exclamation"></i>
          {{ error }}
        </b-alert>
      </div>
      <div class="text-center position-absolute d-block w-100" style="top:50%" v-else>
        <b-spinner>{{$t('operations.loading')}}</b-spinner>
      </div>
    </div>


  </b-modal>

</template>

<script>
import mixinBase from '@/components/mixin/mixinBase';
import mixinNotifications from '@/components/mixin/mixinNotifications';
import { mapActions } from 'vuex';
import BusinessUnitEditDetails from '@/components/partial/BusinessesUnits/BusinessUnitEditDetails';
import User from '@/model/user/User';
import BusinessUnit from '@/model/businessUnit/BusinessUnit';

export default {
  name: 'BusinessUnitModal',
  components: { BusinessUnitEditDetails },
  mixins: [mixinBase, mixinNotifications],
  data: () => ({
    dialog: {
      show: false,
    },
    uniqueId: 0,
    loading: true,
    error: null,
    id: null,
    isNewBusiness: false,
    businessUnit: {}
  }),
  watch: {
    queryParams:{
      handler(){
        if(this.queryParams.userId == null) {
          this.closeModal()
          return
        }
        if (this.queryParams.userId !== this.id) {
          this.showModal(this.queryParams.userId)
        }
      },
      deep: true
    }
  },
  methods: {
    ...mapActions('businessesUnits', ['getBusinessUnitById']),
    showModal(id) {
      this.uniqueId = id
      this.loading = true
      this.dialog.show = true
      this.dialog.tab = 0
      this.isNewBusiness = id == null || id === 0;
      if(!this.isNewBusiness) {
        this.refreshData()
      }else {
        this.businessUnit = BusinessUnit()
        this.error = null
        this.loading = false
      }
      this.pushState({businessUnitId: id, tab: 0, })
    },
    closeModal(){
      this.dialog.show = false;
      this.businessUnit = {}
      this.onDialogClose()
    },
    onDialogClose(){
      this.id = null;
    },
    refreshData() {
      let $this = this;
      this.getBusinessUnitById(this.uniqueId)
          .then(business =>
          {
            $this.businessUnit = business;
            $this.error = null
          }, err => {
            console.log(err);
            $this.error = $this.getErrorDescription(err)
          }).finally(()=>{ $this.loading = false })
    },
  }

};
</script>

<style scoped>

</style>
