<template>
  <div>
    <b-row>
      <b-col cols="12">
        <h4 class="text-primary text-center text-uppercase">
          <i class="fa-solid fa-building-circle-exclamation"></i>
          Details Business Unit
        </h4>
      </b-col>
      <!-- name -->
      <b-col cols="4"  xl="3">
        <label>{{ $t('columns.name') }}</label>
        <b-form-input v-model="businessUnit.name" :readonly="!hasPermission(permissions.BU_UPDATE)"/>
      </b-col>
      <!-- orgId -->
      <b-col cols="4"  xl="3">
        <label>Organization</label>
        <organization-select-box v-model="businessUnit.orgId" :readonly="!hasPermission(permissions.BU_UPDATE)"/>
      </b-col>
      <!-- type -->
      <b-col cols="4"  xl="3">
        <label>Type</label>
        <!--              <select v-model="businessUnit.type">-->
        <!--                <option v-for="(item, key) in businessUnitTypes" :value="key">-->
        <!--                  {{item}}-->
        <!--                </option>-->
        <!--              </select>-->
        <business-unit-type-select-box v-model="businessUnit.type" :readonly="!hasPermission(permissions.BU_UPDATE)"/>
      </b-col>
      <!-- phone-->
      <b-col cols="4"  xl="3">
        <label>Phone</label>
        <b-input-group>
          <b-form-input v-model="businessUnit.phone" :readonly="!hasPermission(permissions.BU_UPDATE)"/>
          <b-input-group-append>
            <a
                class="btn btn-outline-primary" target="_blank"
                v-b-tooltip :title="$t('operations.call')"
            >
              <i class="fa-solid fa-phone-volume text-primary"></i>
            </a>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <!-- email -->
      <b-col cols="4"  xl="3">
        <label>{{ $t('columns.email') }}</label>
        <b-input-group>
          <b-form-input v-model="businessUnit.email" :readonly="!hasPermission(permissions.BU_UPDATE)"/>
          <b-input-group-append>
            <a
                class="btn btn-outline-primary" target="_blank"
                v-b-tooltip :title="$t('operations.sendMail')"
            >
              <i class="fa-solid fa-envelope text-primary"></i>
            </a>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <!-- mailid -->
      <b-col cols="4"  xl="3">
        <label>mailid</label>
        <b-input-group>
          <b-form-input v-model="businessUnit.mailid" :readonly="!hasPermission(permissions.BU_UPDATE)"/>
        </b-input-group>
      </b-col>
      <!--address-->
      <b-col cols="4" xl="3">
        <label>address</label>
        <b-form-input v-model="businessUnit.address" :readonly="!hasPermission(permissions.BU_UPDATE)"/>
      </b-col>
      <!--website-->
      <b-col cols="4" xl="3">
        <label>website</label>
        <b-form-input v-model="businessUnit.website" :readonly="!hasPermission(permissions.BU_UPDATE)"/>
      </b-col>
      <!--contact-->
      <b-col cols="4" xl="3">
        <label>contact</label>
        <b-form-input v-model="businessUnit.contact" :readonly="!hasPermission(permissions.BU_UPDATE)"/>
      </b-col>
      <!--countryCode-->
      <b-col cols="4" xl="3">
        <label>country Code</label>
        <b-form-input v-model="businessUnit.countryCode" :readonly="!hasPermission(permissions.BU_UPDATE)"/>
      </b-col>
      <!--cur-->
      <b-col cols="4" xl="3">
        <label>cur</label>
        <b-form-input v-model="businessUnit.cur" :readonly="!hasPermission(permissions.BU_UPDATE)"/>
      </b-col>
      <!--externalLink-->
      <b-col cols="4" xl="3">
        <label>externalLink</label>
        <b-form-input v-model="businessUnit.externalLink" :readonly="!hasPermission(permissions.BU_UPDATE)"/>
      </b-col>
      <!--token-->
      <b-col cols="4" xl="3">
        <label>token</label>
        <b-form-input v-model="businessUnit.token" :readonly="!hasPermission(permissions.BU_UPDATE)"/>
      </b-col>
      <!--companyClients-->
      <b-col cols="4" xl="3">
        <label>Company Clients</label>
        <b-form-input v-model="businessUnit.companyClients" :readonly="!hasPermission(permissions.BU_UPDATE)"/>
      </b-col>
      <!--companyClients-->
      <b-col cols="4" xl="3">
        <label>Landing Clients</label>
        <b-form-checkbox v-model="businessUnit.landingClients" :readonly="!hasPermission(permissions.BU_UPDATE)"/>
      </b-col>
    </b-row>

    <!-- buttons to save or exit -->
    <div class="text-center mt-2" style="position: absolute;bottom: 10px; width: 100%">
      <b-button block size="md" variant="primary" @click="savingChanges">{{$t('operations.save')}}</b-button>

      <b-button v-if="businessUnit.id!=null" block class="d-inline-block" size="sm" variant="danger" @click="deleteBusinessUnit">
        {{ $t('operations.delete') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import mixinPermissions from '@/components/mixin/mixinPermissions';
import mixinNotifications from '@/components/mixin/mixinNotifications';
import mixinBase from '@/components/mixin/mixinBase';
import BusinessUnit from '@/model/businessUnit/BusinessUnit';
import { mapActions } from 'vuex';
import OrganizationSelectBox from '@/components/widget/select/OrganizationSelectBox';
import BusinessUnitTypeSelectBox from '@/components/widget/select/type/BusinessUnitTypeSelectBox';
import BusinessUnitSelectBox from '@/components/widget/select/BusinessUnitSelectBox';
import businessUnit from '@/model/businessUnit/BusinessUnit';

export default {
  name: 'BusinessUnitEditDetails',
  components: {
    BusinessUnitSelectBox,
    OrganizationSelectBox, BusinessUnitTypeSelectBox
  },
  mixins: [mixinPermissions, mixinNotifications,mixinBase],
  data: () => ({
    businessUnit: BusinessUnit(),
  }),
  props: {
    value: {
      required: true,
      default: () => ({})
    },
    newBusinessUnit: {
      type: Boolean,
      default: () => false
    }
  },
  watch: {
    value: {
      handler() {
        this.businessUnit = Object.assign(BusinessUnit(), this.value)
      },
      immediate: true
    }
  },

  methods: {
    ...mapActions('businessesUnits', ['updateBusinessUnit', 'addNewBusinessUnit', 'deleteBusinessUnitById']),
    resetModel(){
      this.businessUnit = Object.assign(BusinessUnit(), this.value)
    },
    async savingChanges() {
      let $this = this;
      try {
        if (this.businessUnit.id == null)
          await this.addNewMember();
        else
          await this.update();
        this.showSuccessNotification($this.$t('globalMessages.updateDataSuccess', { system: "Business Unit" }));
        this.refreshTables();
        this.$emit('input')

      } catch (e) {
        $this.showErrorNotification($this.$t('globalMessages.fetchDataError',
            { system: "error in update/add business unit" }), e.response.data.error)
      }
    },
    async update(){
      let $this = this;
      let req= {id: $this.businessUnit.id, businessUnit: $this.businessUnit}
      this.updateBusinessUnit(req)
      this.refreshTables();
      this.$emit('input')

    },
    addNewMember(){
      let $this = this;
      let req= {businessUnit: $this.businessUnit}
      console.log("req", req);
      this.addNewBusinessUnit(req)
    },
    async deleteBusinessUnit() {
      let $this = this;
      if(this.businessUnit.id==null)
        return
      try {
        let response = await this.deleteBusinessUnitById($this.businessUnit.id)
        console.log("after delete business unit", response);
        this.showSuccessNotification($this.$t('globalMessages.updateDataSuccess', { system: "Business Unit" }));
        this.refreshTables();
        this.$emit('input')

      }catch (e){
        $this.showErrorNotification($this.$t('globalMessages.fetchDataError',
            { system: "error in delete business unit" }), e.response.data.error)
      }

    }
  }

};
</script>

<style scoped>

</style>
